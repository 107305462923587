<template>
    <div class="team">
        <div class="header-wrapper d-flex justify-content-center align-items-center">
            <div class="container text-center text-light header-container">
                <h1 class="display-4">Unser Team</h1>
                <p class="lead w-75 mx-auto">
                    Falls du duch schon immer einmal gefragt hast, wer Teil des MC ONE Teams ist, findest du hier eine Liste aller
                    Teammitglieder.
                </p>
            </div>
        </div>

        <div class="changelog my-5">
            <div v-if="changelog.length <= 0">

            </div>
            <b-container v-else>
                <b-card bg-variant="light" class="text-center">
                    <div class="mx-auto d-inline-block text-left">
                        <b-card-title class="text-center">Neueste Änderungen</b-card-title>

                        <div v-for="(user, i) in changelog" :key="user.time+user.name">
                            <div v-if="i < limit">
                                <div v-if="user.type === 'joined'">
                                    <fai :icon="['fas', 'arrow-right']" class="mr-1 text-success"/>
                                    <span class="font-weight-bold">{{ user.name }}</span>
                                    <span class="text-secondary">
                                        ist dem <span class="font-weight-bold" :style="{color: user.group_color}">{{ user.group }}</span> Team am {{
                                          getFormattedDate(user.time)
                                        }} beigetreten.
                                    </span>
                                </div>
                                <div v-else-if="user.type === 'changed'">
                                    <fai :icon="['fas', 'arrows-alt-v']" class="mr-1 text-info"/>
                                    <span class="font-weight-bold">{{ user.name }}</span>
                                    <span class="text-secondary">
                                        hat am {{ getFormattedDate(user.time) }} in das
                                        <span class="font-weight-bold" :style="{color: user.group_color}">
                                          {{ user.group }}
                                        </span>
                                        Team gewechselt.
                                    </span>
                                </div>
                                <div v-else-if="user.type === 'leaved'">
                                    <fai :icon="['fas', 'arrow-left']" class="mr-1 text-danger"/>
                                    <span class="font-weight-bold">{{ user.name }}</span>
                                    <span class="text-secondary">
                                        hat am {{ getFormattedDate(user.time) }} das Team verlassen.
                                    </span>
                                </div>
                                <div v-else-if="user.type === 'founded'">
                                    <fai :icon="['fas', 'gavel']" class="mr-1 text-success"/>
                                    <span class="font-weight-bold">{{ user.name }}</span>
                                    <span class="text-secondary">
                                        hat den Server am {{ getFormattedDate(user.time) }} gegründet.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="text-center">
                            <a v-if="limit < changelog.length" href="" @click.stop.prevent="loadChangelog">Mehr Laden</a>
                            <a v-if="limit > 5" href="" @click.stop.prevent="closeChangelog" class="ml-3">Schließen</a>
                        </div>
                    </div>
                </b-card>
            </b-container>
        </div>
        <div class="teamlist">
            <div v-if="ranks.length <= 0" class="d-flex m-4 align-items-center justify-content-center">
                <b-spinner small class="mr-3 text-secondary" variant="secondary" size="sm"/>
                Lädt...
            </div>
            <section v-else v-for="(group, id) in ranks" v-bind:key="id"
                     :class="['text-center', 'pt-4', 'my-3', id % 2 !== 0 ? 'bg-light' : '', group.members.length === 0 ? 'd-none' : '']">
                <b-container>
                    <h1 class="display-4 mb-4" style="word-break: break-all;">
                        {{ !subGroup(group.description) ? group.description : (group.description.substr(0, 8) + '.') }}</h1>
                    <b-row class="mt-5" align-h="center">
                        <b-col v-for="(member, userId) in group.members" v-bind:key="userId" lg="4" md="6" xs="12"
                               class="user-col mb-5">
                            <div :class="['rounded-circle', 'shadow-sm', 'mx-auto', 'mb-2']"
                                 :style="{background: `${group.color}20`}">
                                <b-img rounded="circle" style="opacity: 1"
                                       :src="`https://visage.surgeplay.com/bust/${member.uuid}`"></b-img>
                            </div>
                            <h2>{{ member.name }}</h2>
                            <h4 class="mb-0">
                                <b-badge :style="{background: group.color}">{{ member.group_name }}</b-badge>
                            </h4>
                            <hr class="mb-2"/>
                            <p class="text-muted status mb-0">
                                <span :class="member.status ? '' : 'font-italic'"></span>
                                {{ member.status ? member.status : '' }}
                            </p>
                        </b-col>
                    </b-row>
                </b-container>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'team',
        data() {
            return {
                ranks: [],
                changelog: [],
                limit: 5
            }
        },
        mounted() {
            window.addEventListener('resize', this.resizeHandler)

            this.axios.get(process.env.NODE_ENV === 'production' ? 'https://api.mcone.eu/v1/team' : 'http://localhost:8001/v1/team').then(response => {
                this.ranks = this.combineRanks(response.data.team)
                this.changelog = response.data.changelog
            })
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resizeHandler)
        },
        methods: {
            subGroup(name) {
                return window.innerWidth < 420 && name.length > 8;
            },
            subStatus(status) {
                return status.substr(0, status.length < 40 ? status.length : 40)
            },
            getFormattedDate(timestamp) {
                return new Intl.DateTimeFormat('de').format(new Date(timestamp * 1000))
            },
            combineRanks(ranks) {
                let majorGroups = {}

                rankLoop:
                    for (const rank of ranks) {
                        for (const group of Object.values(majorGroups)) {
                            if (group.common_name === rank.common_name) {
                                if (rank.priority < group.priority) {
                                    group.priority = rank.priority
                                }

                                for (const member of rank.members) {
                                    group.members.push(member)
                                }

                                continue rankLoop
                            }
                        }

                        majorGroups[rank.common_name] = rank
                    }

                console.log(Object.values(majorGroups))
                return Object.values(majorGroups).sort((a, b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0));
            },
            loadChangelog() {
                this.limit += 5
            },
            closeChangelog () {
                this.limit = 5
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header-wrapper {
        background: linear-gradient(
                        to bottom right,
                        rgba(1, 55, 13, 0.7),
                        rgba(15, 0, 0, 0.6)
        ), url('../assets/img/lobby.png') center/cover no-repeat border-box;
        padding-top: 50px;
    }

    .user-col > .rounded-circle, .user-col > .rounded-circle > img {
        height: 150px;
        width: 150px;
    }

    .teamlist {
        min-height: 500px;
    }

    .status {
        overflow-wrap: break-word;
    }
</style>
